<template>
  <div class="home-page" >
    <div class="bg-box">
      <img class="home-bg" src="@/assets/img/login/login-bg.png" />
    </div>
    <div class="autocomplete-container">
      <van-field
      class="card-input"
      v-model="cardNumber"
      placeholder="请输入11/13/15位业务员或19/20位ICCID或卡号"
      :right-icon="scanIcon"
      list="fruits"
      @focus="showList=true"
      @blur="showList=false"
      @click-right-icon="wxScanCode" />
      <ul v-if="filteredFruits.length > 0 && showList" class="autocomplete-list">
        <li v-for="(fruit, index) in filteredFruits" :key="index" @mousedown="selectFruit(fruit)">
          {{ fruit.iccid }}
        </li>
      </ul>
    </div>
    

    <!-- <div class="btn" @click="enquiries">查询充值</div> -->
    <van-button class="btn" :loading="loading" type="primary" loading-text="查询中" color="#13ad92" @click="enquiries">查询充值</van-button>
    <div class="tips" v-if="tipShow">查无此号,请确认后重新输入</div>
    <div class="log-box">
      <img src="@/assets/img/login/lt-log.png" />
      <img src="@/assets/img/login/dx-log.png" />
      <img src="@/assets/img/login/yd-log.png" />
    </div>
  </div>
</template>

<script>
import wxApi from '@/utils/wechatPlugin';
import scanIcon from '@/assets/img/login/scan-icon.png';
import { toFlowIndexPage,getIccidsByOpenId,checkAndTransformIccidByShare } from '../api/home/index.js'
import { toTemFlowPage } from '../api/index/kehu.js'
import { Toast } from 'vant';
// import mixin from "../utils/mixins.js"; // 引入mixin文件
export default {
  name: 'Home',
  // mixins: [mixin],
  data() {
    return {
      cardNumber: '',
      operator:'',
      scanIcon: scanIcon,
      showList:false,
      fruits: [],
      loading:false,
      tipShow:false,
    };
  },
  computed: {
    filteredFruits() {
      return this.fruits;
    },
  },
  mounted() {
    const iccid = this.getUrlParam('iccid')
    if(iccid){
      const iccidShow = this.getUrlParam('iccidShow')
      const operator = this.getUrlParam('operator')
      const msisdn = this.getUrlParam('msisdn')
      sessionStorage.setItem('balanceMppType',5)
      this.$store.commit('SETBALANCEMPPTYPE', 5)
      const data={iccid,iccidShow,operator,msisdn}
      this.goMainPage(data,{})
    }else{
      this.getIccidList()
    }
    this.$store.dispatch('setAllType');
    this.loading=false
      // this.getIccidList()
  },

  methods: {
    getUrlParam(name) {
      let url = window.location.href;
      // 获取 URL 参数部分
      let params = url.split('?')[1];
      // 将参数部分按照 & 分割成数组
      let paramsArray = params?.split('&');
      // 创建一个空对象来存储参数
      let paramsObj = {};
      // 遍历参数数组
      paramsArray?.forEach(function (param) {
        // 将参数按照 = 分割成键值对
        let keyValue = param.split('=');
        // 将键值对存储到对象中
        paramsObj[keyValue[0]] = keyValue[1];
      });
      return paramsObj[name];
    },
    selectFruit(fruit) {
      console.log(fruit);
      this.cardNumber = fruit.iccid;
      this.operator = fruit.operator;
      this.showList=false
    },
    getIccidList(){
      const wxuser = sessionStorage.getItem('wxuser')
      if(!wxuser)return
      getIccidsByOpenId({wxuser}).then(res=>{
        console.log(res.data.iccids);
        this.fruits=res.data.iccids
      })
    },
    enquiries() {
      this.tipShow=false
      if(this.loading)return
      this.loading=true
      if(sessionStorage.getItem('showCoupon'))sessionStorage.removeItem('showCoupon')
      const userId = this.$store.state.balanceMppType == 3 ? sessionStorage.getItem('userId') : ''
      checkAndTransformIccidByShare({
        cardId:this.cardNumber.trim(),
        blockSearchType:this.$store.state.balanceMppType == 3?'':1,
        userId,
      }).then(a=>{
        const requestURL = this.$store.state.balanceMppType == 3 ?window.location.href.split('/')[2]:''
        if(a.data.code==1002||a.data.code==1001)return this.tipShow=true,this.loading=false;
        if(a.data.code==9999)return Toast.fail(a.data.message);
        sessionStorage.setItem('cardNumber',this.cardNumber.trim())
        if(this.$store.state.balanceMppType == 3){
          sessionStorage.setItem('type',(a.data.cardFlag==2||a.data.cardFlag==4)?1:2)
          this.$store.commit('SETCARDTYPE', (a.data.cardFlag==2||a.data.cardFlag==4)?1:2)
        }
        const formData = {userId,operator:a.data.operator,requestURL,iccid:a.data.iccid,iccidShow: this.cardNumber.trim()}
        this.goMainPage(formData,a)
        // const toFunction = this.$store.state.balanceMppType == 3 ? toTemFlowPage : toFlowIndexPage
        // toFunction({
        //   wxuser: sessionStorage.getItem('wxuser'),
        //   operator: a.data.operator,  //	1移动 2 联通 3电信
        //   userId,
        //   requestURL,
        //   iccid: a.data.iccid,
        //   iccidShow: this.cardNumber.trim()
        // }).then(res => {
        //   this.loading=false
        //   sessionStorage.setItem('iccid', a.data.iccid);
        //   if(res.data&&res.data.redirecUri){
        //     if(this.$store.state.balanceMppType == 3&&(a.data.cardFlag==2||a.data.cardFlag==4)){
        //       sessionStorage.setItem('type',1)
        //       this.$router.push({ path: '/mp_group/template_group_index'});
        //     }else{
        //       sessionStorage.setItem('type',res.data.redirecUri.indexOf('new_style_group_index')>-1?1:2)  
        //       this.$router.push({ path: '/'+res.data.redirecUri });
        //     }
        //   }
        // }).catch(() => {
        //   this.loading=false
        // });
      }).catch(() => {
        this.loading=false
      });
    },
    goMainPage(data,a){
      const {userId,requestURL,operator,iccid,iccidShow,msisdn}=data
      const toFunction = this.$store.state.balanceMppType == 3 ? toTemFlowPage : toFlowIndexPage
      toFunction({
        wxuser: sessionStorage.getItem('wxuser'),
        operator,  //	1移动 2 联通 3电信
        userId,
        requestURL,
        iccid,
        iccidShow,
        msisdn,
      }).then(res => {
        this.loading=false
        sessionStorage.setItem('iccid', iccid);
        if(res.data&&res.data.redirecUri){
          if(this.$store.state.balanceMppType == 3&&(a.data.cardFlag==2||a.data.cardFlag==4)){
            sessionStorage.setItem('type',1)
            this.$router.push({ path: '/mp_group/template_group_index'});
          }else{
            sessionStorage.setItem('type',res.data.redirecUri.indexOf('new_style_group_index')>-1?1:2)  
            this.$router.push({ path: '/'+res.data.redirecUri });
          }
        }
      }).catch(() => {
        this.loading=false
      });
    },
    wxScanCode() {
      wxApi.scanQRCode({
          success:  (res) => {
            console.log(res);
            // 在这里处理扫码成功后的逻辑
            let rstr = res.resultStr
            rstr = rstr.split(',')[1]
            if(!rstr) {
              this.$toast("未识别到卡号");
              return ;
            }
            this.cardNumber = rstr
          },
          fail: function (res) {
            console.error('扫码失败', res);
            // 在这里处理扫码失败后的逻辑
          },
      });
    },
    // // 跳转页面
    // topage(value) {
    //   if (this.user) {
    //     this.$router.push({ name: value })
    //   }
    // },
    // // 初始化首页
    // inithome() {
    // },
    // // 获取登录账户的用户信息及token
    // async getuser() {
    //   // 后面的数据为获取的参数传参
    //   await this.$store.dispatch('setuser', '333')
    //   // this.students = res.data
    //   this.studentlist()
    // },
    // // 获取学生列表
    // async studentlist() {

    // },
    // // 获取学生设备及位置信息
    // async getLocation() {
    //   await getStudentLocation({
    //     studentId: this.personage.studentId
    //   }).then(res => {
    //     this.location = res.data
    //     this.initMap()
    //   })
    // }
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  height: 94vh;
  padding: 40px;
  position: relative;
  .bg-box{
    text-align: center;
  }
  .tips{
    margin-top:20px;
    color: red;
    font-size: 32px;
  }
  .home-bg {
    width: 336px;
    height: 362px;
    margin: 100px auto;
  }
  .card-input {
    font-size: 28px;
    border: 1px solid #ededed;
  }
  .btn {
    width: 100%;
    height: 80px;
    background: #13ad92;
    color: #fff;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    line-height: 80px;
    border-radius: 8px;
    margin-top: 40px;
  }
  .log-box {
    display: flex;
    justify-content: center;
    margin: 30vh auto 0 auto;
    img {
      width: 199.2px;
      height: 55.2px;
    }
    img:nth-child(2) {
      margin: 0 60px;
    }
  }
}
.autocomplete-container {
  position: relative;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  background: #fff;
  font-size: 28px;
  border: 1px solid #f0eeee;
  position: absolute;
  top: 80px;
  z-index: 1; /* 确保下拉列表在其他元素上方 */
}

.autocomplete-list li {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
::v-deep .van-icon__image {
  width: 30px;
  height: 30px;
  margin-top:10px;

}
</style>
